<template>
  <div
    class="modal fade"
    id="modalAnexosManifiestos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="
        max-width: 980px;
        position: absolute;
        transform: translate(0, 0%);
        top: 0px;
        right: 100px;
        bottom: 0;
        left: 0;
        z-index: 10040;
        overflow: auto;
        overflow-y: auto;
      "
    >
      <div class="modal-content" style="width: 980px">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ soloConsulta ? "Manifiesto" : "Agregar anexos a manifiesto" }}
            {{ MANIFEST_DB_ID }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- formulario agregar anexo a manifiesto -->

          <div
            id="formularioAdjuntarAnexo"
            class="form-inline"
            v-if="!soloConsulta"
          >
            <div class="custom-file">
              <input
                v-on:change.prevent="onChangeFileUpload()"
                ref="formManifiestoAnexoFile"
                type="file"
                class="custom-file-input"
                id="formManifiestoAnexoFile"
                name="formManifiestoAnexoFile"
                required
              />
              <label
                class="custom-file-label"
                ref="formManifiestoAnexoFileLabel"
                for="formManifiestoAnexoFile"
                >Anexar archivo</label
              >
              <div class="invalid-feedback">....</div>
            </div>

            <div class="form-row mt-5" style="width: 100%">
              <div class="form-group col-md-9">
                <label for="formManifiestoAnexoDescripcion" class>Nota:</label>
                <input
                  type="text"
                  class="form-control-plaintext"
                  ref="formManifiestoAnexoDescripcion"
                  id="formManifiestoAnexoDescripcion"
                  name="formManifiestoAnexoDescripcion"
                />
              </div>
              <div class="form-group mt-4 ml-auto">
                <button
                  v-on:click="fnCrearAnexoManifiesto()"
                  type="submit"
                  class="btn btn-sm btn-primary ml-auto"
                  ref="btnAdjuntarAnexo"
                >
                  Adjuntar anexo
                </button>
              </div>
            </div>
          </div>
          <hr />
          <!-- fin formulario agregar anexo a manifiesto -->

          <!-- TABLA TABLA TABLA de anexos adjuntos al manifiesto -->
          <v-client-table
            id="tblManifiestoAnexo"
            class="mt-2"
            :data="anexos"
            :columns="columns"
            :options="opcionesDeTabla"
          >
            <a
              slot="download"
              slot-scope="props"
              class="fa fa-download"
              :href="VUE_APP_API_URL + 'uploads/' + props.row.anexofile"
              download
              target="_blank"
            ></a>
            <button
              slot-scope="props"
              class="btn btn-sm btn-danger"
              slot="eliminar"
              @click="fnEliminarAnexo(props.row.id)"
              v-if="!soloConsulta"
            >
              Eliminar
            </button>
          </v-client-table>
          <!-- fin TABLA TABLA TABLA de anexos adjuntos al manifiesto -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-float-right"
            data-dismiss="modal"
          >
            Cerrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//inicio
const fetch = require("node-fetch");

const reader = new FileReader();
export default {
  name: "RampasManifiestosModalAnexos",
  props: ["anexoManifiestoRouteId", "MANIFEST_DB_ID", "soloConsulta"],
  watch: {
    anexoManifiestoRouteId: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);

      this.$store
        .dispatch("Anexosmanifiestos/find", {
          query: {
            MANIFEST_ID: newVal,
            $sort: {
              id: -1
            }
          }
        })
        .then(result => {
          console.log(result);
          // fecha: moment(anexo.createdAt).format('d/m/YYYY, hh:mm')

          for (let index = 0; index < result.data.length; index++) {
            result.data[index].fecha = moment(
              result.data[index].createdAt
            ).format("DD-MM-YYYY hh:mm:ss");
          }

          this.anexos = result.data;
        });
    }
  },
  data() {
    return {
      columns: ["id", "descripcion", "anexo", "fecha", "download", "eliminar"],
      anexos: [],
      anexosForm: [],
      anexosFormBase64: [],
      opcionesDeTabla: {
        orderBy: {
          column: "id",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        dateColumns: ["fecha"],
        dateFormat: "DD-MM-YYYY HH:mm",
        filterByColumn: false,
        perPage: 3,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No hay información que mostrar...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["descripcion", "anexo"],
        headings: {
          descripcion: "Nota",
          anexo: "Archivo",
          fecha: "Fecha de subida",
          download: "Visualizar"
        }
      }
    };
  },
  methods: {
    notificacion(type, message) {
      this.$notify({
        type: type,
        verticalAlign: "top",
        horizontalAlign: "right",
        message: message
      });
    },
    onChangeFileUpload() {
      this.anexosForm = this.$refs.formManifiestoAnexoFile.files[0];
      this.$refs.formManifiestoAnexoFileLabel.innerHTML =
        "Anexar archivo: " + this.anexosForm.name;
      //console.log(this.anexosForm);
      reader.readAsDataURL(this.anexosForm);
      reader.onload = function() {
        this.anexosFormBase64 = reader.result;
        //console.log(reader.result);
      };
    },
    fnCrearAnexoManifiesto: function() {
      if (this.$refs.formManifiestoAnexoFile.files.length === 0) {
        //notify //notificación
        this.notificacion("danger", "Por favor adjunte un archivo");
        //poner borde rojo al input de archivo
        this.$refs.formManifiestoAnexoFileLabel.style.border =
          "thin solid #FF4C25";
      } else {
        //disable boton
        this.$refs.btnAdjuntarAnexo.classList.add("disabled");

        let formData = new FormData();
        formData.append("anexos", this.$refs.formManifiestoAnexoFile.files[0]);
        formData.append("MANIFEST_ID", this.anexoManifiestoRouteId);
        formData.append(
          "descripcion",
          this.$refs.formManifiestoAnexoDescripcion.value
        );
        console.log(formData);

        fetch(VUE_APP_API_URL + "anexosmanifiestos", {
          method: "POST",
          headers: new Headers({
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt")
          }),
          body: formData
        }).then(result => {
          // SERVICO PARA RECARGAR LOS DATOS DE ANEXOS ASIGNADOS A ESE MANIFIESTO
          //meter nuevo anexo a la tabla

          this.cargarAnexos();

          //notify //notificación
          this.notificacion(
            "success",
            "Se ha anexado el archivo: " +
              this.$refs.formManifiestoAnexoDescripcion.value
          );

          //#A29A8F
          //borrar formulario para anexar archivos

          this.$refs.formManifiestoAnexoDescripcion.value = "";
          this.$refs.formManifiestoAnexoFile.value = "";
          this.$refs.formManifiestoAnexoFileLabel.innerHTML = "Anexar archivo";
          //poner borde gris al input de archivo
          this.$refs.formManifiestoAnexoDescripcion.style.border =
            "thin solid #A29A8F";
          this.$refs.formManifiestoAnexoFileLabel.style.border =
            "thin solid #A29A8F";
          this.$refs.btnAdjuntarAnexo.classList.remove("disabled");
          //console.log(result);
          this.anexoAdjuntado(this.anexoManifiestoRouteId);
        });
      }
    },
    cargarAnexos() {
      this.$store
        .dispatch("Anexosmanifiestos/find", {
          query: {
            MANIFEST_ID: this.anexoManifiestoRouteId,
            $sort: {
              id: -1
            }
          }
        })
        .then(result => {
          // fecha: moment(anexo.createdAt).format('d/m/YYYY, hh:mm')

          for (let index = 0; index < result.data.length; index++) {
            result.data[index].fecha = moment(
              result.data[index].createdAt
            ).format("DD-MM-YYYY hh:mm:ss");
          }

          this.anexos = result.data;
        });
    },
    anexoAdjuntado(manifestId) {
      this.$emit("anexoAdjuntado", manifestId);
    },
    fnEliminarAnexo(id) {
      const confirmacion = confirm(
        "¿Está seguro de querer eliminar este anexo?"
      );
      if (confirmacion) {
        this.$store.dispatch("Anexosmanifiestos/remove", id).then(result => {
          this.anexoEliminado(this.anexoManifiestoRouteId);
          this.cargarAnexos();
        });
      }
    },
    anexoEliminado(manifestId) {
      this.$emit("anexoEliminado", manifestId);
    }
  },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    }
  },
  mounted() {
    if (this.soloConsulta) {
      this.columns.pop();
    }
  }
};
</script>
<style>
.form-inline label {
  color: black;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  color: grey;
  content: "Búscar archivo" !important;
}
#formManifiestoAnexoDescripcion {
  border: 1px solid rgba(34, 42, 66, 0.2);
  border-radius: 0.2857rem;
}
#formularioAdjuntarAnexo {
  background-color: rgba(34, 42, 66, 0.05);
  border-radius: 15px;
  padding: 20px;
}
.btn-float-right {
  position: absolute !important;
  right: 25px;
  bottom: 10px;
}
</style>
