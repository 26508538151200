<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12">
        <h4>Filtros de búsqueda</h4>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="campania">Campaña</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroCampania"
            id="formFiltroCampania"
            name="formFiltroCampania"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroCampania ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroCampania')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="manifiesto_id">Manifiesto</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroManifiesto"
            id="formFiltroManifiesto"
            name="formFiltroManifiesto"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroManifiesto ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroManifiesto')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group position-relative">
          <label for="manifiesto_id">Número de pedido</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroPedido"
            id="formFiltroPedido"
            name="formFiltroPedido"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroPedido ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroPedido')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group position-relative">
          <label for="manifiesto_id">Nombre de consejera</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroNombreConsejera"
            id="formFiltroNombreConsejera"
            name="formFiltroNombreConsejera"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroNombreConsejera ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroNombreConsejera')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <button class="btn btn-info" @click="buscar" id="btn-buscar">
          Buscar
        </button>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <h4>Resultados</h4>
      </div>
    </div>
    <v-client-table
      ref="tablaPedidosDetalle"
      :data="rows"
      :columns="columns"
      :options="options"
      name="tablaPedidosDetalle"
    >
      <div slot="filter__btnManifiesto">
        <select class="form-control" @change="filtroAnexos">
          <option value>Todos</option>
          <option value="1">Con anexos</option>
          <option value="2">Sin anexos</option>
        </select>
      </div>
      <button
        slot-scope="props"
        class="btn btn-primary btn-sm"
        slot="btnDetalle"
        @click="fnAsignarVariableErpDocDetalle(props.row.ERP_DOC)"
      >
        Detalle
      </button>

      <button
        slot-scope="props"
        class="btn btn-sm"
        slot="btnManifiesto"
        data-toggle="modal"
        data-target="#modalAnexosManifiestos"
        v-bind:class="
          props.row.cantidad_anexos == 0 ? 'btn-danger' : 'btn-success'
        "
        @click="
          fnAsignaranexoManifiestoRouteId(
            props.row.MANIFEST_ID,
            props.row.MANIFEST_DB_ID
          )
        "
      >
        Anexos
      </button>
    </v-client-table>

    <modalEntregasVerDetalle
      @clicked="fnDesasignarErpDocDetalle($event)"
      v-if="entregaERPDOCDetalle !== null"
      v-bind:entregaERPDOCDetalle="entregaERPDOCDetalle"
      v-bind:ventanaInformacionDeDevolucionesHistorico="
        ventanaInformacionDeDevolucionesHistorico
      "
      v-bind:ventanaInformacionDeEntrega="ventanaInformacionDeEntrega"
    ></modalEntregasVerDetalle>

    <RampasManifiestosModalAnexos
      v-bind:anexoManifiestoRouteId="anexoManifiestoId"
      v-bind:MANIFEST_DB_ID="MANIFEST_DB_ID"
      solo-consulta="true"
    ></RampasManifiestosModalAnexos>
  </div>
</template>

<script>
import { Event } from "vue-tables-2";
import modalEntregasVerDetalle from "@/pages/DashboardEntregas/modals/modalEntregasVerDetalle.vue";
import RampasManifiestosModalAnexos from "@/pages/ModuloRampas/RampasManifiestosModalAnexos.vue";
export default {
  components: {
    modalEntregasVerDetalle,
    RampasManifiestosModalAnexos,
  },
  data() {
    return {
      ventanaInformacionDeEntregaDevolucion: null,
      ventanaInformacionDeDevolucionesHistorico: null,
      ventanaInformacionDeEntrega: null,
      // seccion de manifiestos anexos
      entregaERPDOCDevolucion: null,
      entregaERPDOCDetalle: null,
      // seccion de manifiestos anexos
      MANIFEST_DB_ID: "",
      anexoManifiestoId: "",
      anexosDeManifiesto: [],
      fillInput: {
        formFiltroCampania: false,
        formFiltroManifiesto: false,
        formFiltroPedido: false,
        formFiltroNombreConsejera: false,
      },
      columns: [
        "ERP_DOC",
        "ROUTE_ID",
        "DELIVERY_CLIENT_NAME",
        "CREATED",
        "MANIFEST_DB_ID",
        "UPLOADED_DATE",
        "DELIVERY_DRIVER_NAME",
        "DELIVERY_DATE",
        "STATUSTEXT",
        "btnDetalle",
        "btnManifiesto",
      ],
      rows: [],
      options: {
        headings: {
          ERP_DOC: "Pedido",
          ROUTE_ID: "Ruta",
          DELIVERY_CLIENT_NAME: "Consejer@",
          CREATED: "Fecha en picking",
          MANIFEST_DB_ID: "Id manifiesto",
          UPLOADED_DATE: "Fecha de carga",
          DELIVERY_DRIVER_NAME: "Piloto",
          DELIVERY_DATE: "fecha de entrega",
          STATUSTEXT: "Estado",
          btnDetalle: "Ver detalle",
          btnManifiesto: "Manifiesto",
        },
        orderBy: {
          column: "ERP_DOC",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Sin pedidos que mostrar...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["CREATED", "UPLOADED_DATE", "DELIVERY_DATE"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "ERP_DOC",
          "ROUTE_ID",
          "DELIVERY_CLIENT_NAME",
          "CREATED",
          "MANIFEST_DB_ID",
          "DELIVERY_DRIVER_NAME",
          "UPLOADED_DATE",
          "DELIVERY_DATE",
          "STATUSTEXT",
        ],
        customFilters: [
          {
            name: "filtrar-anexos",
            callback: function (row, query) {
              if (query == 1) {
                return row.cantidad_anexos > 0;
              } else if (query == 2) {
                return row.cantidad_anexos == 0;
              } else {
                return row;
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    buscar() {
      const campania = this.$refs.formFiltroCampania;
      const manifiesto = this.$refs.formFiltroManifiesto;
      const pedido = this.$refs.formFiltroPedido;
      const nombreConsejera = this.$refs.formFiltroNombreConsejera;

      if (
        campania.value.length == 0 &&
        manifiesto.value.length == 0 &&
        pedido.value.length == 0 &&
        nombreConsejera.value.length == 0
      ) {
        this.rows = [];
        return;
      }

      this.mostrarIconoLimpiarFiltro(campania);
      this.mostrarIconoLimpiarFiltro(manifiesto);
      this.mostrarIconoLimpiarFiltro(pedido);
      this.mostrarIconoLimpiarFiltro(nombreConsejera);

      this.$store
        .dispatch("Auditoria/find", {
          query: {
            CAMPAING_ID: campania.value.trim(),
            MANIFEST_ID: manifiesto.value.trim(),
            ERP_DOC: pedido.value.trim(),
            DELIVERY_CLIENT_NAME: nombreConsejera.value.trim(),
          },
        })
        .then((response) => {
          this.rows = [];
          response.forEach((PEDIDO) => {
            if (PEDIDO.DELIVERY_RESEND != null && PEDIDO.DELIVERY_RESEND != 0) {
              console.log(PEDIDO.CREATED);
            }
            //CREATED
            if (PEDIDO.CREATED !== null) {
              PEDIDO.CREATED_REPORTE = PEDIDO.CREATED;
              //
              PEDIDO.CREATED = moment(PEDIDO.CREATED, "DD-MM-YYYY HH:mm");
            } else {
              PEDIDO.CREATED_REPORTE = "N/A";
              PEDIDO.CREATED = "N/A";
            }
            //UPLOADED_DATE
            if (PEDIDO.UPLOADED_DATE !== null) {
              PEDIDO.UPLOADED_DATE_REPORTE = PEDIDO.UPLOADED_DATE;
              //
              PEDIDO.UPLOADED_DATE = moment(
                PEDIDO.UPLOADED_DATE,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              PEDIDO.UPLOADED_DATE_REPORTE = "N/A";
              PEDIDO.UPLOADED_DATE = "N/A";
            }

            //DELIVERY_DATE
            if (PEDIDO.DELIVERY_DATE !== null) {
              PEDIDO.DELIVERY_DATE_REPORTE = PEDIDO.DELIVERY_DATE;
              //
              PEDIDO.DELIVERY_DATE = moment(
                PEDIDO.DELIVERY_DATE,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              PEDIDO.DELIVERY_DATE_REPORTE = "N/A";
              PEDIDO.DELIVERY_DATE = "N/A";
            }

            //DELIVERY_DRIVER_NAME
            if (PEDIDO.DELIVERY_DRIVER_NAME) {
              PEDIDO.DELIVERY_DRIVER_NAME =
                PEDIDO.DELIVERY_DRIVER_NAME +
                " (" +
                PEDIDO.DELIVERY_VEHICULE_PLATES +
                ")";
            } else {
              PEDIDO.DELIVERY_DRIVER_NAME = "N/A";
            }
            //MANIFEST_DB_ID
            if (!PEDIDO.MANIFEST_DB_ID) {
              PEDIDO.MANIFEST_DB_ID = "N/A";
            }
            this.rows.push(PEDIDO);
          });
        });
    },
    //se consulta a la base de datos la información del pedido
    fnAsignarVariableErpDocDetalle(erp_doc) {
      console.log(
        "buscar información del pedido (fnAsignarVariableErpDocDetalle)"
      );
      //asignar infmracion del pedido

      let date = new Date();
      let campaing_id =
        ("0" + date.getYear()).slice(-2) +
        ("0" + date.getMonth() + 1).slice(-2);
      // ASIGNAR INFORMACIÓN DEL PEDIDO
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            ERP_DOC: erp_doc,
            //CAMPAING_ID: campaing_id
          },
        })
        .then((result) => {
          console.log(result);
          if (result.data[0].PRIZES === null) {
            result.data[0].PRIZES = 0;
          }

          if (result.data[0].HOME === null) {
            result.data[0].HOME = 0;
          }

          if (result.data[0].STATUS == 1) {
            this.deliveryTextColor = "red";
          } else if (result.data[0].STATUS == 2) {
            this.deliveryTextColor = "green";
          } else if (result.data[0].STATUS == 3) {
            this.deliveryTextColor = "purple";
          } else {
            this.deliveryTextColor = "orange";
          }
          this.ventanaInformacionDeEntrega = result.data[0];
          //ENVIAR NÚMERO DE ERP_DOC AL HISTORICO DE DEVOLUCIONES
          if (
            this.ventanaInformacionDeEntrega.DELIVERY_RESEND != null &&
            this.ventanaInformacionDeEntrega.DELIVERY_RESEND != 0
          ) {
            console.log(
              "ventanaInformacionDeDevolucionesHistorico" +
                this.ventanaInformacionDeEntrega.DELIVERY_RESEND
            );
            this.ventanaInformacionDeDevolucionesHistorico = erp_doc;
          } else {
            this.ventanaInformacionDeDevolucionesHistorico = null;
          }
        });
      //variable erp_doc para detalle
      this.entregaERPDOCDetalle = erp_doc;
    },
    // DES-asignar informacion erp_doc detalle
    fnDesasignarErpDocDetalle(e) {
      this.entregaERPDOCDetalle = null;
      this.ventanaInformacionDeEntrega = null;
      this.ventanaInformacionDeDevolucionesHistorico = null;
    },
    fnAsignaranexoManifiestoRouteId(idManifiesto, MANIFEST_DB_ID) {
      console.log(idManifiesto, MANIFEST_DB_ID);
      this.anexoManifiestoId = idManifiesto;
      this.MANIFEST_DB_ID = MANIFEST_DB_ID;
    },
    filtroAnexos(query) {
      let value = query.target.value;
      Event.$emit(
        "vue-tables.tablaPedidosDetalle.filter::filtrar-anexos",
        value
      );
    },
    mostrarIconoLimpiarFiltro(inputFiltro) {
      this.fillInput[inputFiltro.id] = inputFiltro.value.length > 0;
    },
    limpiarFiltro(id) {
      this.fillInput[id] = false;
      this.$refs[id].value = "";
      this.$refs[id].focus();
      this.buscar();
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style>
#btn-buscar {
  margin-top: 25px;
}
.form-group.position-relative input {
  padding-right: 32px;
}

.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
</style>
