<template>
  <dragItDude
    ref="entregaERPDOCDetalle"
    id="entregaERPDOCDetalle"
    class
    v-if="entregaERPDOCDetalle !== null"
    style="width: 1000px; max-width: 1300px"
  >
    <div class="modal-content" v-if="DetallePedidoVentana">
      <div
        class="modal-header"
        style="background-color: rgb(3 29 106); padding:8px 8px; cursor:move;"
      >
        <span>
          <strong style="color:white;">
            Trazabilidad detallada, información del pedido:
          </strong>
        </span>
        <!-- <img
          v-if="DetallePedidoVentana.DELIVERY_IMAGE != null"
          width="185px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />-->
      </div>
      <div class="modal-body" style="overflow-y: scroll; max-height: 380px">
        <div class="row" ref="detalleDeEntrega">
          <div class="col-md-6">
            <p>
              Número de pedido:
              <strong> {{ DetallePedidoVentana.ERP_DOC }}</strong>
            </p>
            <p>
              CONSEJER@:
              <strong>{{ DetallePedidoVentana.DELIVERY_CLIENT_NAME }}</strong>
            </p>

            <p>
              Teléfono:
              <strong>{{ DetallePedidoVentana.DELIVERY_CLIENT_PHONE }}</strong>
            </p>

            <p>
              Dirección:
              <strong>{{
                DetallePedidoVentana.DELIVERY_CLIENT_ADDRESS
              }}</strong>
            </p>
            <hr />
            <p>
              Contenido:
              <strong>
                {{ DetallePedidoVentana.TOTAL_PACKAGES }} bultos:
                {{
                  DetallePedidoVentana.TOTAL_PACKAGES -
                    DetallePedidoVentana.PRIZES -
                    DetallePedidoVentana.HOME
                }}
                cajas,
                {{ DetallePedidoVentana.PRIZES }} premios Y
                {{ DetallePedidoVentana.HOME }} hogar.
              </strong>
            </p>

            <p v-if="DetallePedidoVentana.STATUS >= 1">
              Empresa:
              <strong>{{ DetallePedidoVentana.DELIVERY_COMPANY_NAME }}</strong>
            </p>

            <p v-if="DetallePedidoVentana.STATUS >= 1">
              Ruta:
              <strong>{{ DetallePedidoVentana.ROUTE_ID }}</strong>
            </p>

            <p v-if="DetallePedidoVentana.STATUS >= 1">
              Piloto que cargó:
              <strong title="piloto">{{
                DetallePedidoVentana.DELIVERY_ASSIGNED_TO_DRIVER_NAME
              }}</strong>
              <strong title="placa"
                >({{
                  DetallePedidoVentana.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES
                }})</strong
              >
            </p>

            <p v-if="DetallePedidoVentana.STATUS >= 1">
              Piloto que entregó:
              <strong title="piloto">{{
                DetallePedidoVentana.DELIVERY_DRIVER_NAME
              }}</strong>
              <strong title="placa"
                >({{ DetallePedidoVentana.DELIVERY_DRIVER_NAME }})</strong
              >
            </p>

            <p v-if="DetallePedidoVentana.STATUS >= 1">
              Placa:
              <strong>{{
                DetallePedidoVentana.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES
              }}</strong>
            </p>
            <hr />
            <tr v-if="DetallePedidoVentana.STATUS >= 1">
              <td>Manifiesto:</td>
              <td>
                <strong>{{ DetallePedidoVentana.MANIFEST_DB_ID }}</strong>
              </td>
            </tr>
            <p>
              <span v-if="DetallePedidoVentana.STATUS == 2">
                ¿Pedido recibido por el titular del pedido?
                <br />
                <strong v-if="DetallePedidoVentana.DELIVERIED_TO_OWNER == 1">
                  <span>Si</span>
                </strong>
                <div v-else>
                  <span>No</span>
                  <br />Entregado a la persona:
                  <strong>{{ DetallePedidoVentana.DELIVERIED_TO_NAME }}</strong>
                  <br />Relación:
                  <strong>{{
                    DetallePedidoVentana.DELIVERIED_TO_RELATIONSHIP
                  }}</strong>
                  <br />No. DPI:
                  <strong>{{ DetallePedidoVentana.DELIVERIED_TO_DPI }}</strong>
                  <br />Número de teléfono en la entrega:
                  <strong>{{
                    DetallePedidoVentana.DELIVERIED_TO_PHONE
                  }}</strong>
                </div>
                <br />
              </span>
            </p>
          </div>

          <!-- segunda columna -->
          <div class="col-md-3">
            <p>
              <span v-if="DetallePedidoVentana.STATUS >= 1">
                Cargado el:
                <strong>{{
                  DetallePedidoVentana.UPLOADED_DATE | moment("utc", "DD/MM/YY")
                }}</strong>
                A las:
                <strong>
                  {{
                    DetallePedidoVentana.UPLOADED_DATE
                      | moment("utc", "HH:mm:ss a")
                  }}</strong
                >
              </span>
              <span
                v-if="
                  DetallePedidoVentana.STATUS >= 1 &&
                    DetallePedidoVentana.supervisor_de_carga
                "
              >
                Cuadrador;
                <strong>{{ DetallePedidoVentana.supervisor_de_carga }}</strong>
              </span>
              <br />
              Estado:
              <strong :style="'color:' + deliveryTextColor + '!important;'">
                {{ DetallePedidoVentana.STATUSTEXT }}
              </strong>
              <br />
              <span v-if="DetallePedidoVentana.STATUS == 1">
                Cargado el día:
                <strong>
                  {{
                    DetallePedidoVentana.UPLOADED_DATE
                      | moment("add", "", "DD-MM-YYYY")
                  }}
                </strong>
                a las:
                <strong>
                  {{
                    DetallePedidoVentana.UPLOADED_DATE
                      | moment("utc", "add", "", "HH:mm:ss")
                  }}
                </strong>
              </span>
              <span v-if="DetallePedidoVentana.STATUS > 1">
                El día:
                <strong>
                  {{
                    DetallePedidoVentana.DELIVERY_DATE
                      | moment("add", "", "DD-MM-YYYY")
                  }}
                </strong>
                a las:
                <strong>
                  {{
                    DetallePedidoVentana.DELIVERY_DATE
                      | moment("add", "", "HH:mm:ss")
                  }}
                </strong>
              </span>
            </p>
            <p>
              <span v-if="DetallePedidoVentana.STATUS > 1">
                Coordenadas de entrega:
                <br />
                <strong>
                  latitud:
                  {{ DetallePedidoVentana.DELIVERY_LAT }}
                </strong>
                <br />
                <strong>
                  longitud:
                  {{ DetallePedidoVentana.DELIVERY_LON }}
                </strong>
                <br />
              </span>
            </p>
            <hr />
            <p>
              <span v-if="DetallePedidoVentana.STATUS == 2">
                Detalles de entrega:
                <br />¿Zona roja?
                <br />
                <strong>
                  <span v-if="DetallePedidoVentana.DELIVERIED_TO_AREA_ROJA == 1"
                    >Si</span
                  >
                  <span v-else>No</span>
                </strong>
                <br />
              </span>
            </p>
            <p v-if="DetallePedidoVentana.STATUS == 2">
              <label for>Firma de recibido:</label>
              <br />
              <img
                id="infoEntregaDELIVERIED_SIGNATURE"
                :src="
                  VUE_APP_API_URL + DetallePedidoVentana.DELIVERIED_SIGNATURE
                "
                alt
                class="responsive-img"
              />
            </p>
            <!-- <p v-else style="background: none; text-align: center;">
              <img width="215px" src="/img/logoLeCleire.png" alt class="img-fluid" style />
            </p>-->
          </div>
          <!-- tercera columna -->
          <div class="col-md-3">
            <p v-if="DetallePedidoVentana.DELIVERY_IMAGE !== null">
              <img
                id="infoEntregaDELIVERY_IMAGE"
                :src="VUE_APP_API_URL + DetallePedidoVentana.DELIVERY_IMAGE"
                alt
                class="responsive-img"
              />
            </p>
          </div>
        </div>
        <div
          class="row"
          ref="detalleDeReenvios"
          v-if="ventanaInformacionDeDevolucionesHistorico"
        >
          <div class="col-md-12">
            <hr />
            <h3>historial de reenvios</h3>
          </div>
          <v-client-table :data="rows" :columns="columns" :options="options">
            <button
              slot-scope="props"
              class="btn btn-primary btn-sm"
              slot="btnDetalle"
              @click="fnShowReenviosInfo(props.row.id)"
            >
              detalle
            </button>
          </v-client-table>
        </div>
      </div>
      <div class="modal-footer justify-content-end">
        <button
          v-if="entregaTemporal"
          class="btn btn-sm btn-info pull-right mr-3"
          style="bottom: 10px"
          @click="fnShowPEDIDOInfo()"
        >
          Ver ultimo detalle
        </button>

        <button
          class="btn btn-sm pull-right float-right"
          style="bottom: 10px"
          @click="fnResetWindow()"
        >
          Cerrar ventana
        </button>
      </div>
    </div>
  </dragItDude>
</template>

<script>
import DragItDude from "vue-drag-it-dude";

export default {
  components: {
    DragItDude
  },
  props: [
    "entregaERPDOCDetalle",
    "ventanaInformacionDeEntrega",
    "ventanaInformacionDeDevolucionesHistorico"
  ],
  data() {
    return {
      //
      entregaHistorial: null,
      //
      entregaTemporal: null,

      // inicio tabla
      columns: [
        "ERP_DOC",
        "ROUTE_ID",
        "DELIVERY_CLIENT_NAME",
        "CREATED",
        "MANIFEST_DB_ID",
        "UPLOADED_DATE",
        "DELIVERY_DRIVER_NAME",
        "DELIVERY_DATE",
        "STATUSTEXT",
        "btnDetalle"
      ],
      rows: [],
      options: {
        multiSorting: {
          name: [
            {
              column: "btnDetalle",
              matchDir: false
            }
          ]
        },
        orderBy: {
          column: "ERP_DOC",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: false,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No hay información que mostrar...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["CREATED", "UPLOADED_DATE", "DELIVERY_DATE"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "ERP_DOC",
          "ROUTE_ID",
          "DELIVERY_CLIENT_NAME",
          "CREATED",
          "MANIFEST_DB_ID",
          "DELIVERY_DRIVER_NAME",
          "UPLOADED_DATE",
          "DELIVERY_DATE",
          "STATUSTEXT"
        ],
        headings: {
          ERP_DOC: "Pedido",
          ROUTE_ID: "Ruta",
          DELIVERY_CLIENT_NAME: "Consejer@",
          CREATED: "fecha en picking",
          MANIFEST_DB_ID: "id manifiesto",
          UPLOADED_DATE: "Fecha de carga",
          DELIVERY_DRIVER_NAME: "Piloto",
          DELIVERY_DATE: "fecha de entrega",
          STATUSTEXT: "Estado",
          btnDevolucion: "Devolucion",
          btnDetalle: "ver detalle"
        }
      }
      // fin tabla
    };
  },
  methods: {
    fnShowPEDIDOInfo() {
      this.entregaTemporal = null;
      this.entregaHistorial = null;
    },
    fnShowReenviosInfo(id) {
      console.log("consultanod info reenvio" + id);
      this.$store
        .dispatch("Historicodevolucionpedidos/find", {
          query: {
            id: id
          }
        })
        .then(r => {
          console.log(r);
          this.entregaTemporal = this.ventanaInformacionDeEntrega;
          this.entregaHistorial = r.data;
        });
    },
    fnResetWindow() {
      this.$emit("clicked");
    }
  },
  watch: {
    ventanaInformacionDeDevolucionesHistorico: function(newVal, oldVal) {
      // if ((newVal != null) & (newVal != 0)) {

      // }

      this.$store
        .dispatch("Historicodevolucionpedidos/find", {
          query: {
            $select: [
              "id",
              "ERP_DOC",
              "ROUTE_ID",
              "DELIVERY_CLIENT_NAME",
              "CREATED",
              "MANIFEST_DB_ID",
              "UPLOADED_DATE",
              "DELIVERY_DRIVER_NAME",
              "DELIVERY_DATE",
              "STATUSTEXT"
            ],
            ERP_DOC: newVal
          }
        })
        .then(r => {
          console.log("historial de cambios");
          r.data.forEach(PEDIDO => {
            PEDIDO.DELIVERY_DATE = moment(PEDIDO.DELIVERY_DATE);
            PEDIDO.CREATED = moment(PEDIDO.CREATED);
            PEDIDO.UPLOADED_DATE = moment(PEDIDO.UPLOADED_DATE);
          });
          this.rows = r.data;
        });
    }
  },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    },
    DetallePedidoVentana: function() {
      if (this.entregaHistorial == null) {
        return this.ventanaInformacionDeEntrega;
      } else {
        return this.entregaHistorial[0];
      }
    },
    deliveryTextColor: function() {
      if (this.DetallePedidoVentana.STATUS == 1) {
        return "red";
      } else if (this.DetallePedidoVentana.STATUS == 2) {
        return "green";
      } else if (this.DetallePedidoVentana.STATUS == 3) {
        return "blue";
      } else {
        return "orange";
      }
    }
  }
};
</script>
<style>
#entregaERPDOCDetalle {
  font-size: 10px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 10px !important;
}
div.VueTables.VueTables--client
  > div:nth-child(1)
  > div
  > div.form-group.form-inline.pull-left.VueTables__search
  > div.VueTables__search-field {
  display: none;
}
</style>
<style scoped>
strong {
  font-weight: 700;
  color: black;
}
</style>
