<template>
  <div>
    <download-excel
      class="pull-right btn-exportar-verde"
      style=""
      :fields="columnasDeExcel"
      :fetch="exportTableData"
      :type="dataExportType"
      :name="'Tabla custodios' + dataExportType"
    >
      <!-- <i style="color:green;font-size:20px;" class="fas fa-file-excel"></i> -->
      Exportar
    </download-excel>
    <v-client-table
      class="mt-5"
      ref="tablaCustodios"
      :data="custodiosRegs"
      :columns="columns"
      :options="options"
      name="tablaCustodios"
    >
      <button
        slot-scope="props"
        class="btn btn-danger btn-sm"
        slot="btnEliminarRegistro"
        @click="fnEliminarRegistro(props.row.id)"
      >
        eliminar
      </button>
    </v-client-table>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";
export default {
  props: ["rangoDeFechasConsulta"],
  components: { downloadExcel: JsonExcel },
  name: "tablaDeCustodios",
  methods: {
    fnCargarRegistrosTabla() {
      this.$store
        .dispatch("Custodios/find", {
          query: {
            fechahora: {
              $gte: moment(this.rangoDeFechasConsulta.startDate).format(
                "YYYY-MM-DDT00:00:00-06:00"
              ),
              $lte: moment(this.rangoDeFechasConsulta.endDate).format(
                "YYYY-MM-DDT23:59:59-06:00"
              ),
            },
          },
        })
        .then((result) => {
          this.custodiosRegs = [];
          this.custodiosRegs = result.data;
        });
    },
    fnEliminarRegistro(id) {
      const confirmar = confirm(
        `¿Está seguro de querer ELIMINAR estos datos?, este proceso no se puede revertir.`
      );
      if (!confirmar) {
        return;
      }
      ///////// FIN DE LA VALIDACIÓN ////////////////

      this.$store.dispatch("Custodios/remove", id);
      this.$emit("deleted", {});
      this.fnCargarRegistrosTabla();
      this.$notify({
        type: "warning",
        verticalAlign: "bottom",
        horizontalAlign: "right",
        message: "El registro se ha eliminado.",
      });
    },
    exportTableData() {
      return this.$refs.tablaCustodios.allFilteredData; // Change "tablaPedidosDetalle" to your ref
    },
  },
  data() {
    return {
      dataExportType: "xls",
      columnasDeExcel: {
        Custodio: "custodio",
        Sector: "sector",
        Destino: "destino",
        Piloto: "piloto",
        Empresa: "empresa",
        "Fecha y hora": "fechahora",
        Empresacustodio: "empresacustodio",
        Locales: "locales",
        Departamentales: "departamentales",
        Extras: "extras",
        Total: "total",
      },
      columns: [
        "custodio",
        "sector",
        "destino",
        "piloto",
        "empresa",
        "fechahora",
        "empresacustodio",
        "locales",
        "departamentales",
        "extras",
        "total",

        "btnEliminarRegistro",
      ],
      custodiosRegs: [],
      options: {
        headings: {
          custodio: "custodio",
          sector: "sector",
          destino: "destino",
          piloto: "piloto",
          empresa: "empresa",
          fechahora: "fechahora",
          empresacustodio: "empresacustodio",
          locales: "locales",
          departamentales: "departamentales",
          extras: "extras",
          total: "total",

          btnEliminarRegistro: "",
        },
        orderBy: {
          column: "empresacustodio",
          ascending: true,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Sin registros que mostrar...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: [""],
        dateFormat: "DD-MM-YYYY HH:mm:ss",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "custodio",
          "sector",
          "destino",
          "piloto",
          "empresa",
          "fechahora",
          "empresacustodio",
          "locales",
          "departamentales",
          "extras",
          "total",
        ],
      },
    };
  },
  mounted() {
    this.fnCargarRegistrosTabla();
    // ********************************************
    const { Custodios } = this.$FeathersVuex.api;
    Custodios.on("created", (custodio) => {
      custodio.fechahora = moment(custodio.fechahora).format(
        "DD-MM-YYYY HH:mm:ss"
      );

      this.custodiosRegs.unshift(custodio);
    });
  },
  watch: {
    rangoDeFechasConsulta: function (newVal, oldVal) {
      this.$store
        .dispatch("Custodios/find", {
          query: {
            fechahora: {
              $gte: moment(newVal.startDate).format(
                "YYYY-MM-DDT00:00:00-06:00"
              ),
              $lte: moment(newVal.endDate).format("YYYY-MM-DDT23:59:59-06:00"),
            },
          },
        })
        .then((result) => {
          this.custodiosRegs = result.data;
        });
    },
  },
};
</script>
<style scoped>
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
.btn-exportar-azul:hover,
.btn-exportar-verde:hover {
  box-shadow: 2px 4px #888888;
}
</style>
