<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <!-- --------------------------- -->
          <!-- <button
            type="button"
            class="btn btn-sm btn-primary"
            data-toggle="modal"
            data-target="#modalCustodios"
          >
            Gestión empresas de custodio
          </button> -->

          <!-- --------------------------- -->
          <table style="display: inline-block">
            <tr>
              <td>
                <label style="line-height: 5px" for="rangoDeFechasConsulta"
                  >Registros entre las fechas:
                </label>
              </td>
              <td>
                <date-range-picker
                  v-model="rangoDeFechasConsulta"
                  ref="rangoDeFechasConsulta"
                  id="rangoDeFechasConsulta"
                  :append-to-body="true"
                  :time-picker24-hour="true"
                  :ranges="false"
                  :locale-data="locale"
                  @update="fnRefrescarVistaCustodiosPorCambioDeFecha()"
                ></date-range-picker>
              </td>
            </tr>
          </table>

          <!-- --------------------------- -->

          <button
            style="
              margin-bottom: 36px;
              margin-left: 15px;
              display: inline-block;
            "
            class="btn btn-sm btn-secondary"
            @click="fnExportarResumen()"
          >
            Exportar resumen
          </button>
        </div>
      </div>
      <div
        class="row"
        ref="resumenRegistrosDeCustodio"
        id="resumenRegistrosDeCustodio"
      >
        <div class="col-md-6">
          <line-chart
            ref="lineChartResumenCustodios"
            :width="508"
            :height="320"
            v-if="datacollection"
            :chart-data="datacollection"
          ></line-chart>
        </div>
        <div class="col-md-6">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Turnos locales</th>

                <th>Turnos departamentales</th>

                <th>Turnos extras</th>

                <th>(Q) locales</th>
                <th>(Q) departamentales</th>
                <th>(Q) extras</th>
                <th>(Q) TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(empresa, index) in data.empresas" :key="index">
                <th>{{ data.empresas[index] }}</th>
                <th>{{ data.locales[index] }}</th>

                <th>{{ data.departamentales[index] }}</th>

                <th>{{ data.extras[index] }}</th>

                <th
                  :title="
                    'Costo por turno: Q.' + data.precio_por_turno_locales[index]
                  "
                >
                  Q.{{ data.costo_locales[index] }}
                </th>
                <th
                  :title="
                    'Costo por turno: Q.' +
                    data.precio_por_turno_departamentales[index]
                  "
                >
                  Q.{{ data.costo_departamentales[index] }}
                </th>
                <th
                  :title="
                    'Costo por turno: Q.' + data.precio_por_turno_extras[index]
                  "
                >
                  Q.{{ data.costo_extras[index] }}
                </th>
                <th>Q.{{ data.costo_total[index] }}</th>
              </tr>
            </tbody>
            <tfoot v-if="data.empresas.length > 0">
              <th>Totales</th>
              <th>{{ totalLocales }}</th>
              <th>{{ totalDepartamental }}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <!-- ------------ -->
    <div class="container-fluid">
      <custodiosform
        :empresasCustodio="empresasCustodio"
        class="mt-3 p-3 formularioCutodios"
        @clicked="fnCreateCustodiosReg($event)"
      ></custodiosform>
    </div>
    <!-- --------------------- -->
    <div class="container-fluid">
      <tablaDeCustodios
        @deleted="fnRenderGrafica()"
        :rangoDeFechasConsulta="rangoDeFechasConsulta"
      ></tablaDeCustodios>
      <modalCustodios
        @updated="fnCargarEmpresasCustodios()"
        :empresasCustodio="empresasCustodio"
      ></modalCustodios>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import html2canvas from "html2canvas";
import LineChart from "./lineChart";
import custodiosform from "./custodiosform";
import tablaDeCustodios from "./tablaDeCustodios";
import modalCustodios from "./modalCustodios";
import { forEach } from "lodash";
export default {
  components: {
    DateRangePicker,
    LineChart,
    custodiosform,
    tablaDeCustodios,
    modalCustodios,
  },
  computed: {
    totalLocales() {
      let total = [];
      if (this.data.locales == []) {
        return 0;
      }
      Object.entries(this.data.locales).forEach(([key, val]) => {
        total.push(val); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
    totalDepartamental() {
      let total = [];
      if (this.data.departamentales == []) {
        return 0;
      }

      Object.entries(this.data.departamentales).forEach(([key, val]) => {
        total.push(val); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },
  data() {
    return {
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      //
      rangoDeFechasConsulta: {
        startDate: new Date(),
        endDate: new Date(),
      },
      empresasCustodio: [],
      datacollection: null,
      data: {
        empresas: [],
        locales: [],
        departamentales: [],
        extras: [],
        costo_locales: [],
        costo_departamentales: [],
        costo_extras: [],
        precio_por_turno_locales: [],
        precio_por_turno_departamentales: [],
        precio_por_turno_extras: [],
        costo_total: [],
      },
    };
  },
  mounted() {
    this.fnRenderizarInformacionEnLaGrafica();

    this.fnRenderGrafica();

    this.fnCargarEmpresasCustodios();

    // ------------------------------------

    const { Custodios, Empresascustodio } = this.$FeathersVuex.api;
    Custodios.on("created", (custodio) => {
      this.fnRenderGrafica();
    });

    Empresascustodio.on("created", (empresaCustodio) => {
      console.log(empresaCustodio);

      this.empresasCustodio.unshift(empresaCustodio);
    });
    // -------------------------------------------
  },
  methods: {
    fnRefrescarVistaCustodiosPorCambioDeFecha() {
      console.log(this.rangoDeFechasConsulta);
      this.fnRenderGrafica();
    },
    fnExportarResumen() {
      html2canvas(document.querySelector("#resumenRegistrosDeCustodio")).then(
        (canvas) => {
          let img = canvas.toDataURL("image/png");
          var link = document.createElement("a");
          link.download = "Resumen de custodios.png";
          link.href = img;
          link.click();
          link.remove();
        }
      );
    },
    fnCargarEmpresasCustodios() {
      this.empresasCustodio = [];
      let vm = this;
      setTimeout(() => {
        vm.$store
          .dispatch("Empresascustodio/find", {
            query: {
              status: 1,
            },
          })
          .then((result) => {
            result.data.forEach((element) => {});
            vm.empresasCustodio = result.data;
          });
      }, 1000);
    },
    fnRenderGrafica() {
      // ----------------------------
      this.$store
        .dispatch("Custodiosgrafica/find", {
          query: { fecha: this.rangoDeFechasConsulta },
        })
        .then((resumen) => {
          this.data.empresas = [];
          this.data.locales = [];
          this.data.departamentales = [];
          this.data.extras = [];
          this.data.costo_locales = [];
          this.data.costo_departamentales = [];
          this.data.costo_extras = [];
          this.data.costo_total = [];
          this.data.precio_por_turno_departamentales = [];
          this.data.precio_por_turno_extras = [];
          this.data.precio_por_turno_total = [];
          (this.data.costo_total = []),
            // prettier-ignore-start
            resumen.forEach((empresaCustodio) => {
              // registros texto
              this.data.empresas.push(empresaCustodio.empresacustodio);
              this.data.locales.push(empresaCustodio.total_locales);
              this.data.departamentales.push(
                empresaCustodio.total_departamentales
              );
              this.data.extras.push(empresaCustodio.total_extras);
              this.data.costo_locales.push(
                empresaCustodio.precio_total_locales
              );
              this.data.costo_departamentales.push(
                empresaCustodio.precio_total_departamentales
              );
              this.data.costo_extras.push(empresaCustodio.precio_total_extras);
              this.data.precio_por_turno_locales.push(
                empresaCustodio.precio_local
              );
              this.data.precio_por_turno_departamentales.push(
                empresaCustodio.precio_departamental
              );
              this.data.precio_por_turno_extras.push(
                empresaCustodio.precio_extra
              );
              this.data.costo_total.push(
                empresaCustodio.precio_total_locales +
                  empresaCustodio.precio_total_departamentales +
                  empresaCustodio.precio_total_extras
              );
              //registros monetarios
            });
          this.fnRenderizarInformacionEnLaGrafica();
          // prettier-ignore-end
        });
    },
    fnCreateCustodiosReg(e) {
      console.log(e);
      this.$store.dispatch("Custodios/create", [e, null]);
      this.$notify({
        type: "success",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: "Registro de custodio creado con éxito",
      });
    },
    fnRenderizarInformacionEnLaGrafica() {
      this.datacollection = {
        labels: this.data.empresas,
        datasets: [
          {
            label: "local",
            backgroundColor: "#00000000",
            borderColor: "#031D6A",
            data: this.data.locales,
          },
          {
            label: "departamental",
            backgroundColor: "#00000000",
            borderColor: "#2d865e",
            data: this.data.departamentales,
          },
          {
            label: "extras",
            backgroundColor: "#00000000",
            borderColor: "#e59efa",
            data: this.data.extras,
          },
        ],
      };
    },
  },
};
</script>

<style>
.small {
  max-width: 508px;
}
@media (min-width: 992px) {
  .container-scroll > .row {
    overflow-x: auto;
    white-space: nowrap;
  }
  .container-scroll > .row > .col-md-2 {
    display: inline-block;
    float: none;
  }
}

.container-scroll > .row {
  margin-top: 24px;
}
.container-scroll > .row > .col-md-2 {
  font-weight: bold;
  text-align: center;
}
.formularioCutodios {
  background: lavender;
  border-radius: 15px;
}
tfoot {
  background-color: beige;
}
tfoot th {
  padding: 5px 7px !important;
  font-size: 12px;
}
</style>
