<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <p>
          Por favor carga el archivo de excel con los registros de custodios:
        </p>
        <input
          type="file"
          class="my_input"
          id="upload"
          @change="cargarPedidosExcel"
        />
      </div>
      <div class="col-md-12" v-if="arrayRegistrosCustodios.length > 0">
        <button class="btn btn-sm btn-info" @click="enviarRegistroCustodio">
          ingresar
        </button>
        <table class="table table-hover">
          <thead>
            <tr>
              <td>No.</td>
              <td>NOMBRE DEL CUSTODIO</td>
              <td>EMPRESA DE CUSTODIO</td>
              <td>SECTOR</td>
              <td>DESTINO</td>
              <td>PILOTO</td>
              <td>TRANSPORTE</td>
              <td>FECHA DE SERVICIO</td>
              <td>TURNO LOCAL</td>
              <td>TURNO DEPARTAMENTAL</td>
              <td>TOTAL DE SERVICIO PRESTADO</td>
              <td>TURNO EXTRA</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(custodio, index) in arrayRegistrosCustodios"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ custodio.custodio }}</td>
              <td>{{ custodio.empresacustodio }}</td>
              <td>{{ custodio.sector }}</td>
              <td>{{ custodio.destino }}</td>
              <td>{{ custodio.piloto }}</td>
              <td>{{ custodio.empresa }}</td>
              <td>{{ custodio.fechahora }}</td>
              <td>{{ custodio.locales }}</td>
              <td>{{ custodio.departamentales }}</td>
              <td>{{ custodio.total }}</td>
              <td>{{ custodio.extras }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import xlsx from "xlsx";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "custodiosform",
  components: { DateRangePicker },
  props: ["empresasCustodio"],
  data() {
    return {
      arrayRegistrosCustodios: [],
      empresasSelected: "",
      //
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      //
      empresas: [],
      registroCustodio: {
        custodio: "",
        sector: "",
        destino: "",
        piloto: "",
        empresa: "",
        fechahora: {
          startDate: new Date(),
        },
        empresacustodio: "",
        locales: "",
        departamentales: "",
        extras: "",
        total: "",
        obrservacion: "",
      },
    };
  },
  methods: {
    cargarPedidosExcel(e) {
      this.arrayRegistrosCustodios = [];
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const excelContent = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(excelContent, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false,
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the excelContent of the first sheet
          const excellist = []; // Clear received excelContent
          // Edit excelContent
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }

          for (let index = 0; index < excellist.length; index++) {
            let newRegistroDeCustodio = {
              destino: excellist[index]["DESTINO"],
              empresacustodio: excellist[index]["EMPRESA DE CUSTODIO"],
              fechahora: excellist[index]["FECHA DE SERVICIO"],
              custodio: excellist[index]["NOMBRE DEL CUSTODIO"],
              piloto: excellist[index]["PILOTO"],
              sector: excellist[index]["SECTOR"],
              total: excellist[index]["TOTAL DE SERVICIO PRESTADO"],
              empresa: excellist[index]["TRANSPORTE"],
              departamentales: excellist[index]["TURNO DEPARTAMENTAL"],
              extras: excellist[index]["TURNO EXTRA"],
              locales: excellist[index]["TURNO LOCAL"],
            };
            this.arrayRegistrosCustodios.push(newRegistroDeCustodio);
          }
          //console.log(this.arrayRegistrosCustodios);

          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    },
    calcularTotal(e) {
      this.registroCustodio.total =
        Number(this.registroCustodio.locales) +
        Number(this.registroCustodio.departamentales) +
        Number(this.registroCustodio.extras);
    },
    enviarRegistroCustodio(value) {
      this.$emit("clicked", this.arrayRegistrosCustodios);
    },
  },
  mounted() {
    this.$store
      .dispatch("ListadoEmpresasTransportistas/find", {
        query: {
          $select: ["id", "codigo", "nombre"],
        },
      })
      .then((result) => {
        this.empresas = result.data;
      });
    // ----------------
    const { Custodios } = this.$FeathersVuex.api;

    Custodios.on("created", () => {
      this.arrayRegistrosCustodios = [];
    });
  },
  computed: {
    total: function () {
      let totalSum =
        this.registroCustodio.locales +
        this.registroCustodio.departamentales +
        this.registroCustodio.extras;
      this.registroCustodio.total = totalSum;
      return totalSum;
    },
  },
};
</script>
