<template>
  <div
    class="modal fade"
    id="modalCustodios"
    tabindex="-1"
    role="dialog"
    aria-labelledby="gestionDeEmpresasCustodio"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="gestionDeEmpresasCustodio">
            Empreas de custodio
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table>
            <thead>
              <tr>
                <td>Nombre</td>
                
                <td>Costo por turno local</td>
                <td>Costo por turno departamental</td>
                <td>Costo por turno extra</td>
              </tr>
            </thead>
            <tbody v-if="!editState">
              <tr>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Empresa"
                    name="nombre"
                    ref="nombre"
                    v-model="newEmpresa.nombre"
                    required
                  />
                </td>
                
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="precio_local"
                    placeholder="Costo local"
                    name="precio_local"
                    ref="precio_local"
                    v-model="newEmpresa.precio_local"
                    required
                  />
                </td>
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="precio_departamental"
                    placeholder="Costo departamental"
                    name="precio_departamental"
                    ref="precio_departamental"
                    v-model="newEmpresa.precio_departamental"
                    required
                  />
                </td>
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="precio_extra"
                    placeholder="Costo extra"
                    name="precio_extra"
                    ref="precio_extra"
                    v-model="newEmpresa.precio_extra"
                    required
                  />
                </td>
                <td>
                  <button
                    class="btn btn-md btn-success"
                    @click="fnCreateEmpresaCustodios()"
                  >
                    crear
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-if="editState">
              <tr>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Empresa"
                    name="nombre"
                    ref="nombre"
                    v-model="EditEmpresa.nombre"
                    required
                  />
                </td>
                
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="EDIT-precio_local"
                    placeholder="Costo local"
                    name="EDIT-precio_local"
                    ref="EDIT-precio_local"
                    v-model="EditEmpresa.precio_local"
                    required
                  />
                </td>
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="EDIT-precio_departamental"
                    placeholder="Costo departamental"
                    name="EDIT-precio_departamental"
                    ref="EDIT-precio_departamental"
                    v-model="EditEmpresa.precio_departamental"
                    required
                  />
                </td>
                <td>
                  <input
                    type="numeric"
                    class="form-control"
                    id="EDIT-precio_extra"
                    placeholder="Costo extra"
                    name="EDIT-precio_extra"
                    ref="EDIT-precio_extra"
                    v-model="EditEmpresa.precio_extra"
                    required
                  />
                </td>
                <td v-if="!editState">
                  <button
                    class="btn btn-md btn-success"
                    @click="fnCreateEmpresaCustodios()"
                  >
                    crear
                  </button>
                </td>
                <td v-if="editState">
                  <button
                    class="btn btn-link text-warning"
                    @click="fnEditarEmpresaCustodio()"
                  >
                    Editar
                  </button>
   
                </td>
                <td v-if="editState">
       
                  <button
                    class="btn btn-link text-danger"
                    @click="fnSetEditEmpresaCustodio()"
                  >
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table">
            <thead>
              <tr>
                <td>Nombre</td>
                <td>Costo por turno departamental</td>
                <td>Costo por turno local</td>
                <td>Costo por turno extra</td>
                <td>Acciones</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(empresa, index) in empresasCustodio" :key="index">
               <td>
                 {{ empresa.nombre }}
               </td>
               <td>
                 {{ empresa.precio_departamental }}
               </td>
               <td>
                 {{ empresa.precio_local }}
               </td>
               <td>
                 {{ empresa.precio_extra }}
               </td>
                <td>
                  <button
                    class="btn btn-sm btn-yellow mr-1"
                    @click="fnSetEditEmpresaCustodio(empresa)"
                  >
                    editar
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    @click="fnEliminarEmpresaCustodios(empresa.id, empresa.nombre)"
                  >
                    eliminar
                  </button>
                </td>
              </tr>
                
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary pull-right"
            data-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["empresasCustodio"],
  data() {
    return {
      editState : false,
      empresas: [],
      newEmpresa: {
        nombre: null,
        precio_departamental: null,
        precio_local: null,
        precio_extra: null,
      },
      EditEmpresa: {
        id:null,
        nombre: null,
        precio_departamental: null,
        precio_local: null,
        precio_extra: null,
        
      },
    };
  },
  methods: {
    fnSetEditEmpresaCustodio(empresa){

      if( empresa == null){
        this.EditEmpresa.id =  null;
        this.EditEmpresa.nombre =  null;
        this.EditEmpresa.precio_departamental =  null;
        this.EditEmpresa.precio_local =  null;
        this.EditEmpresa.precio_extra =  null;
        this.editState = false
      }else{
        this.EditEmpresa.id = empresa.id;
        this.EditEmpresa.nombre = empresa.nombre;
        this.EditEmpresa.precio_departamental = empresa.precio_departamental;
        this.EditEmpresa.precio_local = empresa.precio_local;
        this.EditEmpresa.precio_extra = empresa.precio_extra;
        this.editState = true
      }
      
    },
    fnEditarEmpresaCustodio(){
      const confirmar = confirm(
        `¿Está seguro de querer editar los datos de la empresa ${this.EditEmpresa.nombre}?, este proceso no se puede revertir.`
      );
      if (!confirmar) {
        return;
      }
      ///////// FIN DE LA VALIDACIÓN ////////////////
      this.$store.dispatch('Empresascustodio/patch', [this.EditEmpresa.id, this.EditEmpresa, {}])
      this.$notify({
            type: "warning",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Empresa de custodios editada con éxito",
          });
    },
    fnEliminarEmpresaCustodios(id,nombre){
      
      const confirmar = confirm(
        `¿Está seguro de querer eliminar los datos de la empresa ${nombre}?, este proceso no se puede revertir.`
      );
      if (!confirmar) {
        return;
      }
      ///////// FIN DE LA VALIDACIÓN ////////////////
      this.$store.dispatch('Empresascustodio/patch', [id, {status:0}, {}])
      this.$emit("updated", null);
      
      this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Empresa de custodios eliminada con éxito",
          });
    },
    fnCreateEmpresaCustodios() {
      if(this.newEmpresa.nombre == null){
        this.$refs.nombre.style.border= "1px solid red"
        this.$refs.nombre.focus()
        this.$notify({
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
          message: "El campo 'nombre' es obligatorio.",
        });
        return;
      }
      if(this.newEmpresa.precio_departamental == null || this.newEmpresa.precio_departamental <= 0){
        this.$refs.precio_departamental.style.border= "1px solid red"
        this.$refs.precio_departamental.focus()
        this.$notify({
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
          message: "El campo 'Costo por turno departamental' es obligatorio.",
        });
        return;
      }
      if(this.newEmpresa.precio_local == null || this.newEmpresa.precio_local <= 0){
        this.$refs.precio_local.style.border= "1px solid red"
        this.$refs.precio_local.focus()
        this.$notify({
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
          message: "El campo 'Costo por turno local	' es obligatorio.",
        });
        return;
      }
      if(this.newEmpresa.precio_extra == null || this.newEmpresa.precio_extra <= 0){
        this.$refs.precio_extra.style.border= "1px solid red"
        this.$refs.precio_extra.focus()
        this.$notify({
          type: "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
          message: "El campo 'Costo por turno extra' es obligatorio.",
        });
        return;
      }
      // -------------------------------------------
      this.$refs.nombre.style.border= "1px solid #1d253b"
      this.$refs.precio_departamental.style.border= "1px solid #1d253b"
      this.$refs.precio_local.style.border= "1px solid #1d253b"
      this.$refs.precio_extra.style.border= "1px solid #1d253b"
      this.$store
        .dispatch("Empresascustodio/create", this.newEmpresa)
        .then((result) => {
          //   --------------------------------
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Empresa de custodios creada con éxito",
          });
          //   -------------------------------
          this.newEmpresa.nombre = "";
          this.newEmpresa.precio_departamental = 0.0;
          this.newEmpresa.precio_local = 0.0;
          this.newEmpresa.precio_extra = 0.0;
        });
    },

  },
  mounted() {
    console.log(this.empresasCustodio);
    //this.fnCreateEmpresaCustodios();
  },
};
</script>
<style scoped>
td {
  margin-right: 15px;
}
.btn-yellow{
  background-image: linear-gradient(to bottom left, #d39e00, #e0a800, #d39e00) !important;
  background-color: #e0a800!important; border-color: #d39e00!important;
}
</style>

