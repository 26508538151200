<template>
  <div id="app">
    <div class="row">
      <div class="titulo-dashboard col-md-12">
        <h1>Gerencia de Operaciones</h1>
        <h2>Sistema de trazabilidad de entregas de pedidos a domicilio</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="modulo in modulos" :key="modulo.modulo_url">
        <a :href="'' + modulo.modulo_url" class="a-card">
          <card class="card card-effect">
            <!-- MOSTRAR ICONO DE MODOULO SEGUN LIBRERIA -->
            <h4
              slot="header"
              class="card-title"
              v-if="modulo.modulo_icon_type == 1"
            >
              <i :class="'tim-icons text-primary ' + modulo.modulo_icono"></i>
              {{ modulo.modulo_nombre }}
            </h4>

            <h4
              slot="header"
              class="card-title"
              v-if="modulo.modulo_icon_type == 2"
            >
              <i :class="'text-primary mdi ' + modulo.modulo_icono"></i>
              {{ modulo.modulo_nombre }}
            </h4>

            <!-- MOSTRAR ICONO DE MODOULO SEGUN LIBRERIA -->
            <p class="card-text">
              {{ modulo.modulo_descripcion }}
            </p>
          </card>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      modulos: []
    };
  },
  computed: {},
  methods: {},
  mounted() {
    document.querySelector("body").style.overflow = "auto";
  },
  created() {
    const usuario = this.$store.state.auth.payload.usuarios;

    this.$store
      .dispatch("UsuarioAutorizacionModulo/get", usuario.id)
      .then(result => {
        this.modulos = result.modulos;
      });
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-text {
  margin-top: -6px !important;
}
</style>
<style>
.titulo-dashboard {
  text-align: center;
  text-shadow: 2px 2px 4px #000000 !important;
}
.titulo-dashboard h1 {
  color: #ffffff !important;
  font-weight: 500 !important;
}
.titulo-dashboard h2 {
  color: #ffffff !important;
  font-weight: 500 !important;
}
.card.card-effect {
  height: 155px;
  webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 200ms ease;
}
.card.card-effect:hover {
  box-shadow: 0px 12px 17px -7px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -o-transition: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.card .card-header .card-title {
  font-weight: 500 !important;
}
</style>
